// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


//import Rails from "@rails/ujs"
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")




// JS imports
require('jquery')
import('src/plugins')  // loads popper.js and bootstrap async
import('src/headers')  // loads h1 fonts and pokermaths async
import('src/linkfix')  // loads linkfix scripts async
//require("katex");
// CSS imports
require('css/site');


import('src/pokermath')
import('src/add-eu-banner')


// Turbolinks.start()

//const images = require.context('../../../vendor/assets/images', true)
require.context('../images', true)
//Rails.start()
